//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-32:_1668,_7748,_5832,_7820,_6660,_1088,_5984,_3964;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-32')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-32', "_1668,_7748,_5832,_7820,_6660,_1088,_5984,_3964");
        }
      }catch (ex) {
        console.error(ex);
      }