//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-32:_9404,_7460,_3728,_4108,_8012,_3504,_9016,_3028;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-32')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-32', "_9404,_7460,_3728,_4108,_8012,_3504,_9016,_3028");
        }
      }catch (ex) {
        console.error(ex);
      }