const productDataIgnoredFields = [
  'AvailabilityMessage',
  'CatalogID',
  'Category',
  'DateCreated',
  'FreeShipping',
  'GTIN',
  'HasOptions',
  'MFGID',
  'Manufacturer',
  'Name',
  'OnSale',
  'Price',
  'RegularPrice',
  'Sale',
  'ProductLink',
  'ReviewAverage',
  'ReviewCount',
  'SKU',
  'ThumbnailFile',
  'Stock',
  'ProductCategory',
  '_docid',
  '_score',
  '_rank',
];
const ignoreFields = ['Category'];

globalThis.Convermax.getCategory = () => {
  const breadcrumbAnchors = [...window.document.querySelectorAll('section.breadcrumnb a')];
  const categoryAnchor = breadcrumbAnchors[breadcrumbAnchors.length - 1];
  const { href } = categoryAnchor;
  const nameElem = categoryAnchor.querySelector('[itemprop="name"]');
  const name = (nameElem.innerText || nameElem.textContent).trim();
  return { href, name };
};

export default {
  platform: 'shift4shop',
  searchPageUrl: '/search',
  useLocationHash: true,
  SearchRequestDefaults: {
    pageSize: 48,
  },
  product: {
    noImageSrc: 'https://abolox.com/assets/images/default.jpg',
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'SearchPageCategory',
      type: 'SearchPage',
      location: '.category-products',
      template: 'SearchPage',
    },
    {
      name: 'SearchBoxDialogButton',
      location: '.site-header #searchBox',
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'Price': 'Price: Low to High',
        'Price:desc': 'Price: High to Low',
        'Name': 'Name',
        'DateCreated:desc': 'Newest',
      },
    },
    {
      name: 'ProductDetailsFilter',
      type: 'ProductData',
      location: { insertAfter: '#listing .addToCartBlock' },
      template: 'product/productData',
      ignoredFields: productDataIgnoredFields,
    },
    {
      name: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'SearchResult',
      updateCallback,
    },
    {
      name: 'facet-dialog',
      type: 'ContextDialog',
      template: 'Dialog',
      ignoreFields,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
  ],
};

function updateCallback() {
  // copy-paste from shift4shop core.js

  /* eslint-disable */
  jQuery('.product-item .quickview').click(function (n) {
    n.preventDefault();
    var t = jQuery(this).parents('.product-item'),
      i = jQuery(t).data('catalogid'),
      r = jQuery(t).data('categoryid');
    quickview('/product.asp?lt_c=1&itemid=' + i + '&qv=1');
  });

  typeof window.lozad != 'function' &&
    (jQuery('div.product-items').each(function () {
      var n = 0;
      jQuery('.product-item', this).each(function (t, i) {
        var r = jQuery(i).height();
        r > n && (n = r);
      });
      jQuery(this).attr('data-itemsHeight', n);
    }),
    jQuery('div.product-items').each(function (n, t) {
      var i = jQuery(t).attr('data-itemsHeight');
      i != undefined && i + '' != '' && jQuery(t).find('.product-item').height(i);
    }));
  /* eslint-enable */
}
