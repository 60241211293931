
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function productDataRT () {
    function repeatValue1(prop, propIndex, attribute, field, isCategoryField, category, value, valueIndex) {
        return [
            _createElement('a', {
                'href': isCategoryField ? value.Url : this.getSearchLink({
                    facetSelection: [{
                            field,
                            term: value
                        }],
                    customHref: category.href
                }),
                'target': '_blank',
                'key': '6811'
            }, '\n        ', isCategoryField ? value.Category : value, '\n      '),
            valueIndex < attribute.length - 1 ? [', '] : null
        ];
    }
    function scopeAttributeFieldIsCategoryFieldCategory2(prop, propIndex) {
        var attribute = prop.value;
        var field = prop.field.replace(/_/g, ' ');
        var isCategoryField = field === 'CategoryList';
        var category = window.Convermax.getCategory();
        return _createElement.apply(this, [
            'div',
            { 'key': field },
            '\n    ',
            isCategoryField ? 'Category' : field,
            ':\n\n    ',
            !Array.isArray(attribute) ? [_createElement('a', {
                    'href': this.getSearchLink({
                        facetSelection: [{
                                field,
                                term: value
                            }],
                        customHref: category.href
                    }),
                    'target': '_blank',
                    'key': '4531'
                }, '\n        ', attribute, '\n      ')] : null,
            Array.isArray(attribute) ? _map(attribute, repeatValue1.bind(this, prop, propIndex, attribute, field, isCategoryField, category)) : null
        ]);
    }
    function repeatProp3(prop, propIndex, attribute, field, isCategoryField, category) {
        return scopeAttributeFieldIsCategoryFieldCategory2.apply(this, [
            prop,
            propIndex
        ]);
    }
    return _createElement.apply(this, [
        'div',
        { 'className': 'cm_product-details-container' },
        _createElement('label', {}, 'Item Attributes:'),
        _map(this.productData.filter(p => p !== 'CategoryList'), repeatProp3.bind(this))
    ]);
}
        export const componentNames = []